import "../styles/globals.css";
import type { AppProps } from "next/app";
import { NextPage } from "next";
import Layout from "../components/Layout";

export type NextPageWithCustomLayout = NextPage & {
    getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithCustomLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout =
        Component.getLayout ?? ((page) => <Layout>{page}</Layout>);

    return getLayout(<Component {...pageProps} />);
}
