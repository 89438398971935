import classNames from "classnames";

interface Props {
    children: React.ReactNode;
    hero?: React.ReactNode | undefined;
}

const Layout: React.FC<Props> = ({ children, hero }) => {
    return (
        <>
            <div
                className={classNames({
                    "absolute z-10 w-full py-6": hero != undefined,
                })}
            >
            </div>
            {hero}
            <main>{children}</main>
        </>
    );
};

export default Layout;
